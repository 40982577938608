import React from 'react';
import PropTypes from 'prop-types';
import { useIntl } from 'gatsby-plugin-intl';

import styles from './cart-item-registration-total.module.scss';

const CartItemRegistrationTotal = ({ number, price }) => {
  const intl = useIntl();

  const checkout = intl.formatMessage({ id: 'checkout' });
  const item = intl.formatMessage({ id: 'item' });
  const items = intl.formatMessage({ id: 'items' });

  return (
    <div className={styles.item}>
      <div className={styles.number}>
        {checkout}
        <span>({number}</span>
        {Number(number) === 1 ? `${item}` : `${items}`}) :
      </div>

      {!!price && <div className={styles.price}>{`${price}$`}</div>}
    </div>
  );
};

CartItemRegistrationTotal.propTypes = {
  number: PropTypes.number.isRequired,
  price: PropTypes.number.isRequired,
};

export default CartItemRegistrationTotal;

/* eslint-disable no-use-before-define */
import React, { useEffect, useRef, useState } from 'react';
import clsx from 'clsx';
import Flag from 'react-world-flags';
import LiveChat from 'react-livechat';
import moment from 'moment';
import { useFormik } from 'formik';
import { useDispatch, useSelector } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import { graphql, useStaticQuery } from 'gatsby';
import { checkSearchableSelect } from '../helpers/instanceHandler';
import Layout from '../components/layout';
import SEO from '../components/seo';
import InputBlockRegistation from '../components/inputBlockRegistation';
import Input from '../components/input';
import InputPassword from '../components/inputPassword';
import InputSelect from '../components/inputSelect';
import DatePicker from '../components/date-picker';
import InputPhone from '../components/input-phone';
import CountrySelect from '../components/country-select';
import Checkbox from '../components/checkbox';
import Button from '../components/button';
import CartItemRegistration from '../components/cart-item-registration';
import CartItemRegistartionTotal from '../components/cart-item-registration-total';
import { strings, getValidationSchema } from '../components/pre-checkout';
import validateEmail from '../services/api/actions/validateEmail';
import validateUserName from '../services/api/actions/validateUserName';
import {
  selectCart,
  selectIbpProduct,
  selectIfIbpInTheCart,
  selectProductsFiltered,
} from '../store/selectors';
import { selectRegisterValues } from '../store/selectors/global';
import { FETCH_REFFERIAL_INFO } from '../store/constants';
import {
  selectCalculatedPrice,
  selectEntityUserDetails,
  selectRefferalInfo,
  selectCountriesInRegion,
  selectEntityUserSubscriptions,
  selectMetaSiteInfo,
} from '../store/selectors/entities';
import {
  addToCart,
  saveReferralInfo,
  setLoader,
  startRegistration,
  updateProductInfo,
} from '../store/actions';

import {
  availableLanguages,
  getInstanceName,
  minDate,
  today,
  getLanguage,
  isUserLogged,
  getLiveChatParams,
} from '../helpers/utils';

import { getCountryData } from '../helpers/countries';

import styles from '../styles/pages/pre-checkout.module.scss';
import signUp from '../services/api/actions/signUp';
import { createNavigateTo, pageLinks } from '../helpers/navigation';
import changeProfileInfo from '../services/api/actions/changeProfileInfo';

export const query = graphql`
  query {
    ibpFluid: file(relativePath: { eq: "products/independant-brand-promoter-170x170.png" }) {
      childImageSharp {
        fluid(quality: 90, maxWidth: 170) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
  }
`;

const defaultEnrollId = '23039';

const initialValues = {
  country: '',
  countryCode: '',
  email: '',
  birthDate: '',
  enrollerId: '',
  firstName: '',
  middleName: '',
  language: '',
  lastName: '',
  loginName: '',
  loginPassword: '',
  phone: '',
  address: '',
  city: '',
  zipCode: '',
  ssn: '',
  privacy: false,
  gdpr: false,
  terms: false,
  refund: false,
  emailVerified: false,
  placementType: '',
  placementToken: '',
  loginNameVerified: false,
  // companyCheckbox: false,
  coApplicantCheckbox: false,
  // companyName: '',
  dateFound: '',
  // companyRegistrationNumber: '',
  taxFilingNumber: '',
  SIRETNumber: '',
  IntraCommunityVATNumber: '',
  taxCode: '',
  coapplicantFirstName: '',
  coapplicantLastName: '',
};

const legacyLinkConfig = [
  {
    link: pageLinks.privacy,
    label: strings.privacyPolicy,
  },
  {
    link: pageLinks.policiesAndProcedures,
    label: strings.policiesAndProcedures,
  },
  {
    link: pageLinks.gdpr,
    label: strings.gdprPolicy,
  },
  {
    link: pageLinks.terms,
    label: strings.termsOfUse,
  },
  {
    link: pageLinks.ibi,
    label: strings.ibi,
  },
  {
    link: pageLinks.compensationPlan,
    label: strings.compensationPlan,
  },
];

const getFlag = (name) => <Flag code={name} width="32" height="16" />;

const options = availableLanguages.map(({ value, label, locale }) => ({
  value,
  label: (
    <div className="inputFlagSelect">
      {getFlag(locale)}
      <span>{label}</span>
    </div>
  ),
}));

const getLanguageByLocale = (locale) => {
  const foundLanguage = getLanguage(locale);

  return options.find((lang) => lang.value === foundLanguage?.value);
};

const getLanguageValueById = (id) => options.find((lang) => lang.value === id);

const stateSelector = createStructuredSelector({
  productList: selectProductsFiltered,
  cartData: selectCart,
  storedValues: selectRegisterValues,
  userData: selectEntityUserDetails,
  ibpProduct: selectIbpProduct,
  isIbpInTheCart: selectIfIbpInTheCart,
  calculatedPrice: selectCalculatedPrice,
  availableCountryCodes: selectCountriesInRegion,
  subscriptions: selectEntityUserSubscriptions,
  metaSiteInfo: selectMetaSiteInfo,
});

function createDocLink(docPath, countryName) {
  if (!countryName) {
    return docPath;
  }
  const searchParams = new URLSearchParams(`country=${countryName}`);
  return `${docPath}?${searchParams.toString()}`;
}

const RegistrationPage = () => {
  const dispatch = useDispatch();
  const refferalInfo = useSelector(selectRefferalInfo);
  const data = useStaticQuery(query);
  const liveChatRef = useRef(null);

  const {
    storedValues,
    userData,
    availableCountryCodes,
    cartData: { products, subTotal, productCount },
    ibpProduct,
    isIbpInTheCart,
    calculatedPrice,
    subscriptions,
    metaSiteInfo,
  } = useSelector(stateSelector);

  const liveChatParams = getLiveChatParams(userData, subscriptions);

  const isUser = !!userData;
  const ibpFluid = data?.ibpFluid.childImageSharp.fluid;
  const ibpPrice = products[0]?.setupPrice || '';
  const ibpTotal = calculatedPrice?.totalAmount || 0;
  const redirectToCheckout = userData?.ssn;
  const [savedValues, setSaveValues] = useState(null);
  const [nameError, setNameError] = useState('');
  const [emailError, setEmailError] = useState('');

  const isRefferalInfoPresent = refferalInfo?.customerId;
  const startedWithReferralLink = refferalInfo?.startedWithLink;

  if (isRefferalInfoPresent) {
    initialValues.enrollerId = refferalInfo?.webalias || refferalInfo?.customerId;
    initialValues.placementType = refferalInfo.placementType;
    initialValues.placementToken = refferalInfo.placementToken;
  }

  const setIbpProductToCart = () => dispatch(addToCart('product', ibpProduct));
  const checkIbiCart = () => {
    if (!isIbpInTheCart && ibpProduct) {
      setIbpProductToCart();
    }
  };

  const updateProducts = () => {
    dispatch(updateProductInfo());
  };

  React.useEffect(() => {
    updateProducts();
  }, []);

  React.useEffect(() => {
    if (redirectToCheckout) {
      createNavigateTo(pageLinks.checkout)();
    }
  }, []);

  React.useEffect(() => {
    checkIbiCart();
  });

  React.useEffect(() => {
    if (liveChatRef?.current?.disable_sounds) {
      liveChatRef?.current?.disable_sounds();
    }
  }, [liveChatRef?.current]);

  // const enrollerId = isUser ? userData?.enroller?.customerId : defaultEnrollId;

  const lang = options.find((item) => item.value === fk?.values?.language);

  const prepareCompanyRegNumber = () => {
    if (France && fk?.values?.IntraCommunityVATNumber) {
      return { companyRegistrationNumber: fk.values.IntraCommunityVATNumber };
    }

    if (!France && fk?.values?.companyRegistrationNumber) {
      return { companyRegistrationNumber: fk.values.companyRegistrationNumber };
    }

    return {};
  };

  const prepareTaxFillingNumber = () => {
    if (France && fk?.values?.SIRETNumber) {
      return { taxFilingNumber: fk.values.SIRETNumber };
    }

    if (!France && fk?.values?.taxFilingNumber) {
      return { taxFilingNumber: fk.values.taxFilingNumber };
    }

    return {};
  };

  // eslint-disable-next-line no-shadow
  const updateUser = async (data) => {
    await dispatch(changeProfileInfo.action(data));
    await dispatch(setLoader(false));
  };

  const scrollToError = () => {
    const el = document.querySelector('.error-scroll');
    (el?.parentElement ?? el)?.scrollIntoView({
      behavior: 'smooth',
      block: 'center',
      inline: 'nearest',
    });
  };

  const fk = useFormik({
    initialValues:
      savedValues ||
      (storedValues.phone
        ? {
            ...storedValues,
            enrollerId: refferalInfo ? refferalInfo.webalias || '' : '',
            phone: storedValues.phone.replace(`+${getCountryData(storedValues.country).phone}`, ''),
          }
        : null) ||
      initialValues,
    validationSchema: () => getValidationSchema(fk?.values, isUser),
    enableReinitialize: true,
    initialTouched: initialValues,
    // eslint-disable-next-line consistent-return
    onSubmit: async (values) => {
      if (!values.emailVerified) {
        const isEmailValid = await validateEmailAddress();
        if (!isEmailValid) return;
      }

      const phone = values.countryCode
        ? `${values.countryCode}${values.phone}`
        : `+${getCountryData(values.country).phone}${values.phone}`;

      const birthDate = moment(values.birthDate).format('YYYY-MM-DD');

      const dataToSend = {
        country: values?.country?.toUpperCase(),
        email: values.email,
        firstName: values.firstName.trim(),
        middleName: values.middleName,
        lastName: values.lastName.trim(),
        language: Number(values.language),
        zipCode: values.zipCode,
        city: values.city,
        address: values.address,
        birthDate,
        loginName: values?.loginName.replace(/\u200C/g, ''),
        loginPassword: values.loginPassword.replace(/\u200C/g, ''),
        ssn: values.ssn.replace(/\u200C/g, ''), // number
        instanceType: 'ibi_shop',
        phone,
        ...(values.coapplicantFirstName
          ? { coapplicantFirstName: values.coapplicantFirstName }
          : {}),
        ...(values.coapplicantLastName ? { coapplicantLastName: values.coapplicantLastName } : {}),
        ...(values.companyName ? { companyName: values.companyName } : {}),
        ...prepareCompanyRegNumber(),
        ...prepareTaxFillingNumber(),
        ...(values.dateFound ? { dateOfIncorporation: values.dateFound } : {}),
        ...(values.taxCode ? { taxCode: values.taxCode } : {}),
        // eslint-disable-next-line no-nested-ternary
        enrollerId: refferalInfo
          ? refferalInfo.customerId.toString()
          : values.enrollerId.length
          ? values.enrollerId
          : defaultEnrollId,
      };

      let selectedCountry;

      if (metaSiteInfo?.currentInstance?.countries) {
        selectedCountry = metaSiteInfo?.currentInstance?.countries?.find(
          (item) => item?.code === dataToSend.country
        );
      }

      console.log('selectedCountry', selectedCountry);

      if (
        (selectedCountry && !selectedCountry.phoneVerificationEnabled) ||
        values.phone.includes('117788') ||
        ['br'].includes(values.country.toLowerCase())
      ) {
        // ?WITHOUT TWILIO
        if (isUser) {
          updateUser(dataToSend);
          return;
        }

        const signUpResponse = await dispatch(signUp.action(dataToSend));
        await dispatch(setLoader(false));

        if (signUpResponse?.payload?.data?.accessToken) {
          createNavigateTo(pageLinks.registrationSuccess)();
        }
        return;
      }

      //WITH TWILIO
      dispatch(
        startRegistration({
          ...dataToSend,
        })
      );
      createNavigateTo(pageLinks.otp)();
    },
  });

  useEffect(() => {
    if (fk.values.country && fk.errors.country) {
      fk.setFieldError('country', null);
    }
  }, [fk.values.country, fk.errors.country]);

  useEffect(() => {
    if (userData) {
      const countryCode = `+${getCountryData(userData.country).phone}`;
      const phone = `${userData?.phone}`.replace(countryCode, '');
      const language = getLanguageByLocale(userData?.languageId);

      fk.setValues({
        ...fk.values,
        firstName: userData?.firstName,
        lastName: userData?.lastName,
        middleName: userData?.middleName,
        country: userData?.country,
        loginName: userData?.username,
        email: userData?.email,
        phone,
        language: language?.value || 0,
        city: userData?.city,
        address: userData?.address,
        birthDate: userData?.dob,
        ssn: userData?.ssn,
        zipCode: userData?.zipCode,
        emailVerified: userData?.emailVerified,
      });
    }
  }, []);

  if (refferalInfo && refferalInfo.error && !fk.errors.enrollerId) {
    fk.setFieldError('enrollerId', 'Please enter correct Referral ID or Referral Name');
  }

  if (refferalInfo && refferalInfo.customerId && fk.errors.enrollerId && !refferalInfo.error) {
    const errors = { ...fk.errors };
    delete errors.enrollerId;
    fk.setErrors(errors);
  }

  const countryData = getCountryData(fk?.values?.country);
  const countryName = countryData ? countryData.name : '';

  // const userIcons = <i className="icon-users" />;

  // const submitDisabled = !fk.isValid || emailError.length > 0;
  const submitDisabled =
    !!emailError.length || !fk.isValid || !fk.values.terms || !fk.values.privacy;

  console.log('fk.isValid', fk);

  const handleBlur = (e) => {
    if (!fk.errors[e.target.id] && !fk.values[e.target.id].length) {
      fk.handleBlur(e);
    }
  };

  const handleBooleans = (key) => () => {
    fk.setFieldValue(key, !fk.values[key]);
  };

  const onEnrollerFieldBlur = (e) => {
    const value = e.target.value.trim();

    if (value.length) {
      dispatch({ type: FETCH_REFFERIAL_INFO, payload: value });
    } else {
      dispatch(saveReferralInfo(null));
      // dispatch(updateProducts(process.env.GATSBY_INSTANCE_COUNTRY));
      if (fk.errors.enrollerId) {
        const errors = { ...fk.errors };
        delete errors.enrollerId;
        fk.setErrors(errors);
      }
    }
    // handleBlur(e);
  };

  const handleFocus = (e) => {
    // Needs to prevent autofill without cursor
    if (fk.isValid) {
      return;
    }

    if (e.target.value.trim().length) {
      setSaveValues({
        ...fk.values,
        loginName: fk.values.loginName.trim(),
        loginPassword: fk.values.loginPassword.trim(),
      });
      fk.resetForm(savedValues);
    }
  };

  const validateEmailAddress = async () => {
    if (!fk.errors.email) {
      fk.setFieldTouched('email');
      const responseData = await dispatch(
        validateEmail.withQuery(`?email=${encodeURIComponent(fk.values.email)}`).action()
      );
      const { payload, error } = responseData;

      if (payload && !payload.data.isSameAddress) {
        fk.setFieldValue('emailVerified', true);
        setEmailError('');
        return true;
      }
      if (payload && payload.data.isSameAddress) {
        fk.setFieldError('email', 'Email address already taken');
        setEmailError('Email address already taken');
        return false;
      }
      if (error) {
        fk.setFieldError('email', responseData.error.response.data.message);
        setEmailError('Email address already taken');
        return false;
      }
      return true;
    }
    return true;
  };

  const validateUserNameField = async () => {
    if (!fk.errors.loginName && fk.values.loginName.length) {
      fk.setFieldTouched('loginName');
      const value = fk.values.loginName.replace(/\u200C/g, '');
      const responseData = await dispatch(validateUserName.action({ username: value }));
      const { payload, error } = responseData;
      if (error) {
        fk.setFieldError('loginName', responseData.error.response.data.message);
        setNameError(responseData.error.response.data.message);
        return;
      }
      if (payload) {
        fk.setFieldValue('loginNameVerified', true);
        setNameError('');
      }
    }

    if (fk.errors.loginName && fk.values.loginName === '') {
      setNameError('');
    }
  };

  const showErrorMessage = (id) =>
    (((fk.values[id] && fk.values[id].length) || fk.touched[id]) && fk.errors[id]) || '';

  // Fixed unsupported regExp on mobile browsers
  const filterSymbols = (e) => {
    e.target.value = e.target.value
      .replace('  ', ' ')
      .replace('``', '`')
      .replace("''", "'")
      .replace("`'", '`')
      .replace("'`", "'");
    return e;
  };

  const USA = fk.values.country.toLowerCase() === 'us';
  const Canada = fk.values.country.toLowerCase() === 'ca';
  const France = fk.values.country.toLowerCase() === 'fr';

  const scrollToClassInjector = (name) => (showErrorMessage(name) ? 'error-scroll' : '');

  const renderForm = () => (
    <form onSubmit={fk.handleSubmit} className={styles.form} noValidate>
      <h2 className={clsx(styles.pageTitle)}>{strings.welcome}</h2>
      <div className={clsx(styles.subtitle)}>{strings.createAnAccount}</div>
      <InputBlockRegistation title={strings.yourFullNameText}>
        <div className={clsx(styles.inputsWrapper, styles.inputsWrapper_three_cols)}>
          <Input
            id="firstName"
            label={strings.firstNameText}
            placeholder={strings.firstNameText}
            onChange={(e) => fk.handleChange(filterSymbols(e))}
            value={fk.values?.firstName}
            onBlur={handleBlur}
            disabled={isUser}
            fullwidth
            required
            errorMessage={showErrorMessage('firstName')}
            wrapperClassName={scrollToClassInjector('firstName')}
          />

          <Input
            id="middleName"
            label={strings.middleNameText}
            placeholder={strings.middleNameText}
            onChange={(e) => fk.handleChange(filterSymbols(e))}
            onBlur={handleBlur}
            value={fk.values?.middleName}
            fullwidth
            errorMessage={showErrorMessage('middleName')}
          />

          <Input
            id="lastName"
            label={strings.lastNameText}
            placeholder={strings.lastNameText}
            onChange={(e) => fk.handleChange(filterSymbols(e))}
            onBlur={handleBlur}
            value={fk.values?.lastName}
            disabled={isUser}
            fullwidth
            required
            errorMessage={showErrorMessage('lastName')}
            wrapperClassName={scrollToClassInjector('lastName')}
          />
        </div>
      </InputBlockRegistation>
      {!isUser && (
        <InputBlockRegistation
          title={
            <div>
              Referral Information
              {!startedWithReferralLink ? (
                <span className={styles.lowercase}> (optional)</span>
              ) : (
                ''
              )}
            </div>
          }
        >
          <div className={clsx(styles.inputsWrapper)}>
            <Input
              id="enrollerId"
              label={strings.referralIDRefferalNameText}
              placeholder="optional"
              onChange={fk.handleChange}
              value={fk.values.enrollerId}
              onBlur={onEnrollerFieldBlur}
              fullwidth
              // disabled={strings.startedWithReferralLink}
              required={false}
              errorMessage={showErrorMessage('enrollerId')}
              popover={strings.referralPopover}
              wrapperClassName={scrollToClassInjector('enrollerId')}
            />

            {isRefferalInfoPresent && (
              <div className={clsx(styles.enrollerName)}>
                <Input
                  id="name"
                  label={strings.referralFullName}
                  placeholder=""
                  value={`${refferalInfo.firstName} ${refferalInfo.lastName}`}
                  fullwidth
                  disabled
                  onChange={() => {}}
                />
              </div>
            )}
          </div>
        </InputBlockRegistation>
      )}

      <InputBlockRegistation title={strings.personalInformation}>
        <div className={clsx(styles.inputsWrapper)}>
          <CountrySelect
            id="country"
            value={fk.values?.country}
            label={strings.countryText}
            placeholder={strings.yourCountryText}
            isDisabled={isUser && userData?.country !== 'PH'}
            onChange={(e) => {
              fk.setFieldValue('country', e.value);
              fk.setFieldValue('countryCode', '');
            }}
            required
            filterKeys={userData?.country === 'PH' ? ['sg'] : availableCountryCodes}
            isSearchable={checkSearchableSelect()}
            errorMessage={showErrorMessage('country')}
            wrapperClassName={scrollToClassInjector('country')}
          />

          <Input
            id="email"
            label={strings.email}
            placeholder={strings.email}
            onChange={fk.handleChange}
            onBlur={validateEmailAddress}
            value={fk.values?.email}
            fullwidth
            disabled={isUser}
            type="email"
            required
            errorMessage={showErrorMessage('email') || emailError}
            wrapperClassName={scrollToClassInjector('email')}
          />

          <div>
            <InputPhone
              id="phone"
              label={strings.phone}
              placeholder={strings.phoneNumber}
              onChange={fk.handleChange}
              onBlur={handleBlur}
              value={fk.values?.phone}
              country={fk.values?.country}
              countryCode={fk.values?.countryCode}
              setFieldValue={fk.setFieldValue}
              fullwidth
              type="tel"
              required
              errorMessage={showErrorMessage('phone')}
              wrapperClassName={scrollToClassInjector('phone')}
            />
          </div>

          <InputSelect
            id="language"
            label={strings.language}
            options={options}
            placeholder={strings.language}
            defaultValue={getLanguageValueById(fk.values.language) || lang}
            onChange={(e) => fk.setFieldValue('language', e.value)}
            required
            errorMessage={showErrorMessage('language')}
            wrapperClassName={scrollToClassInjector('language')}
          />

          <DatePicker
            id="birthDate"
            label={strings.dateOfBirthday}
            value={fk.values?.birthDate}
            onChange={(e) => {
              fk.setFieldTouched('birthDate');
              fk.setFieldValue('birthDate', e);
            }}
            required
            errorMessage={showErrorMessage('birthDate')}
            wrapperClassName={scrollToClassInjector('birthDate')}
            minDate={minDate}
            maxDate={today}
          />

          <Input
            id="city"
            label={strings.city}
            placeholder={strings.city}
            onChange={fk.handleChange}
            // onBlur={validateEmailAddress}
            value={fk.values?.city}
            fullwidth
            required
            errorMessage={showErrorMessage('city')}
            wrapperClassName={scrollToClassInjector('city')}
          />

          <Input
            id="address"
            label={strings.address}
            placeholder={strings.address}
            onChange={fk.handleChange}
            // onBlur={validateEmailAddress}
            value={fk.values?.address}
            fullwidth
            required
            errorMessage={showErrorMessage('address')}
            wrapperClassName={scrollToClassInjector('address')}
          />

          <Input
            id="zipCode"
            label={strings.postalCode}
            placeholder={strings.postalCode}
            onChange={fk.handleChange}
            // onBlur={validateEmailAddress}
            value={fk.values?.zipCode}
            fullwidth
            required
            errorMessage={showErrorMessage('zipCode')}
            wrapperClassName={scrollToClassInjector('zipCode')}
          />

          <Input
            id="ssn"
            label={France || USA || Canada ? strings.ssn : strings.ssnWorldWide}
            placeholder={France || USA || Canada ? strings.ssn : strings.ssnWorldWide}
            onChange={(e) => fk.setFieldValue('ssn', e.target.value)}
            value={fk.values?.ssn}
            fullwidth
            required
            // pattern={ssnPattern}
            errorMessage={showErrorMessage('ssn')}
            wrapperClassName={scrollToClassInjector('ssn')}
          />
        </div>
      </InputBlockRegistation>

      <InputBlockRegistation title={strings.createUsernamePassword}>
        <div className={clsx(styles.inputsWrapper)}>
          <Input
            id="loginName"
            name="loginName"
            label={strings.createUsername}
            placeholder={strings.yourUsername}
            onChange={(e) => {
              fk.setFieldValue('loginName', e.target.value.trim(), true);
            }}
            onBlur={validateUserNameField}
            value={fk.values?.loginName}
            fullwidth
            // icon={fk.values.loginName ? iconChecked : null}
            required
            errorMessage={showErrorMessage('loginName') || nameError}
            onfocus={handleFocus}
            disabled={isUser}
          />
          {!isUser && (
            <InputPassword
              id="loginPassword"
              label={strings.yourPassword}
              placeholder={strings.createPassword}
              value={fk.values?.loginPassword}
              onChange={(e) => fk.setFieldValue('loginPassword', e.target.value.trim(), true)}
              onBlur={handleBlur}
              fullWidth={false}
              required
              errorMessage={showErrorMessage('loginPassword')}
              onfocus={handleFocus}
              disabled={isUser}
            />
          )}
        </div>
      </InputBlockRegistation>

      <div className={styles.divider} />
      <div className={styles.showCompany}>
        <Checkbox
          onChange={handleBooleans('coApplicantCheckbox')}
          checked={fk.values?.coApplicantCheckbox}
        >
          <span className={styles.toggleLabel}>
            <i className="icon-add-user" />
            {strings.coApplicant}
          </span>
        </Checkbox>
      </div>
      {fk.values?.coApplicantCheckbox && (
        <>
          <p className={styles.formTitle}>{strings.coApplicantOptional}</p>
          <div className={styles.row}>
            <div className={clsx(styles.col, styles.mdX2)}>
              <Input
                fullwidth
                id="coapplicantFirstName"
                name="coapplicantFirstName"
                value={fk.values?.coapplicantFirstName}
                label={strings.coapplicantFirstName}
                placeholder={strings.coapplicantFirstName}
                onChange={fk.handleChange}
                errorMessage={showErrorMessage('coapplicantFirstName')}
              />
            </div>
            <div className={clsx(styles.col, styles.mdX2)}>
              <Input
                fullwidth
                id="coapplicantLastName"
                name="coapplicantLastName"
                value={fk.values?.coapplicantLastName}
                label={strings.coapplicantLastName}
                placeholder={strings.coapplicantLastName}
                onChange={fk.handleChange}
                errorMessage={showErrorMessage('coapplicantLastName')}
              />
            </div>
          </div>
        </>
      )}

      <div className={styles.divider} />

      <InputBlockRegistation bordered={false}>
        <Checkbox
          id="terms"
          checked={fk.values?.terms}
          onChange={() => fk.setFieldValue('terms', !fk.values?.terms)}
          errorMessage={showErrorMessage('terms')}
        >
          <div className={clsx(styles.checkboxChildren)}>
            {strings.confirm18}
            {legacyLinkConfig.map((config) => (
              <a
                key={config.link}
                href={createDocLink(config.link, countryName)}
                target="_blank"
                rel="noopener noreferrer"
                className={clsx(styles.checkboxLink)}
              >
                {config.label}
              </a>
            ))}
          </div>
        </Checkbox>

        <div className={styles.checkboxPrivacy}>
          <Checkbox
            id="terms"
            checked={fk.values?.privacy}
            onChange={() => fk.setFieldValue('privacy', !fk.values?.privacy)}
            errorMessage={showErrorMessage('privacy')}
          >
            <div className={clsx(styles.checkboxChildren)}>{strings.allowToStoreYourInfo}</div>
          </Checkbox>
        </div>
      </InputBlockRegistation>

      <InputBlockRegistation bordered={false}>
        <div className={clsx(styles.inputsWrapper)}>
          <Button
            withArrow
            color="primary"
            size="large"
            fullWidth
            type="submit"
            disabled={submitDisabled}
            className={clsx(styles.submitButton)}
            onClick={() => setTimeout(scrollToError, 1000)}
          >
            {strings.getStarted}
          </Button>
        </div>
      </InputBlockRegistation>
    </form>
  );

  const renderCart = () => (
    <div className={styles.cartItemsWrapper}>
      <CartItemRegistartionTotal
        number={productCount}
        price={isUserLogged() ? ibpTotal : subTotal || ibpPrice}
      />
      {products.map((p) => (
        <CartItemRegistration
          key={`precheckout-${p?.name}-${p?.level}-${p?.price}`}
          name={p?.title}
          price={p?.pricing?.price}
          color={p?.color}
          bgColor={p?.bgColor}
          icon={p?.icon}
          bgType={`${p?.productName}-${p?.level}`.toLowerCase()}
          ibpFluid={ibpFluid}
          imageUrl={p?.imageUrl}
        />
      ))}
    </div>
  );
  const siteName = getInstanceName();

  return (
    <>
      <SEO title={`${strings.title} - ${siteName}`} />
      <Layout pageWrapperClassName={clsx(styles.page_wrapper, 'withBannerHeader')}>
        <div className={styles.wrapper}>
          <div className={styles.formWrapper}>{renderForm()}</div>
          <div className={styles.cartWrapper}>{renderCart()}</div>
          {!!userData && (
            <LiveChat
              visitor={{
                name: `${userData?.firstName} ${userData?.lastName}`,
                email: userData?.email,
                username: userData?.loginName,
              }}
              license={process.env.GATSBY_LIVECHAT_LICENSE}
              onChatLoaded={(ref) => {
                liveChatRef.current = ref;
              }}
              params={liveChatParams}
            />
          )}
        </div>
      </Layout>
    </>
  );
};

export default RegistrationPage;
